<template>
  <div class="login-container-form">
    <template v-if="!recoverSentPassword">
      <div class="login-container__form-text">
        <h1>Recuperação de senha</h1>
        <p>Envie seu e-mail cadastrado para recuperar seu acesso</p>
      </div>

      <CommonErrorMessage
        :show="isError"
        :message="errorMessage" 
        class="login__error-message"
      />

      <div>
        <form @submit.prevent="sendRecoverPassword">
          <label 
            :class="{ error: isError }"
            for="email"
          >
            Informe o e-mail cadastrado
            <input 
              v-model="email"
              type="text" 
              name="email" 
              id="email" 
              placeholder="Digite seu e-mail"
            >
          </label>
          <button :class="{ disabled: loading }" type="submit">
            Recuperar senha
          </button>
        </form>
      </div>
    </template>
    <template v-else>
      <div class="login-container__form-text">
        <h1>
          <img src="@/assets/img/tela-inicial/check-laranja.svg" alt="E-mail de Recuperação de Senha enviado!"> 
          <br> 
          E-mail de Recuperação de <br> Senha enviado!
        </h1>
        <p>Acesse seu e-mail para seguir com o login!</p>
      </div>
      <div>
        <form action="">
          <router-link 
            to="/login" 
            class="login-container--btn-to-login"
          >
            Voltar para o login
          </router-link>
        </form>
      </div>
    </template>
    <div class="login-container-form-seguro">
      <span><img src="@/assets/img/tela-inicial/seguro.svg" alt="Acesso seguro 100%"> Acesso 100% seguro</span>
      <p>Seus dados pessoas estão protegidos, todas as <br>
        informações são criptografadas para a sua segurança.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    CommonErrorMessage: () => import(/* webpackChunkName: "CommonErrorMessage" */ '@/components/common/CommonErrorMessage.vue')
  },
  data() {
    return {
      recoverSentPassword: false,
      email: '',
      isError: false,
      errorMessage: 'Preencha corretamente seu email.',
      loading: false,
    }
  },
  watch: {
    email() {
      this.isError = !this.validEmail;
    }
  },  
  computed: {
    validEmail() {
      const regex = /^[a-z0-9\-_.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
      return regex.test(this.email);
    },
  },
  methods: {
    async sendRecoverPassword() {
      if (!this.loading && this.validEmail) {
        this.loading = true;

        await this.$axios
          .post(
            '/usuarios/senha/solicitar-recuperacao', 
            { 
              email: this.email 
            }
          ).catch(err => {
            this.isError = true;
            this.errorMessage = err.data.message || err.data.mensagem;
          });

        this.loading = false;
      }
    }
  }
}
</script>

<style scoped>
a.login-container--btn-to-login {
  color: #fff;
}
</style>